import validate from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/pages/runtime/validate.js";
import call_45tracking_45global from "/home/runner/work/flagman/flagman/apps/frontstore/app/middleware/callTracking.global.ts";
import manifest_45route_45rule from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  call_45tracking_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sanctum:auth": () => import("/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt-auth-sanctum@0.6.3/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt-auth-sanctum@0.6.3/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}