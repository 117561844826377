
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index1haQOeHC_9TvAnzKyhnfAbCrE4Yo_BUP_gkDQ8PFVRAMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/index.vue?macro=true";
import { default as policyptKIJZRyoyRnoieGEND1iAUWoSU0CJyjW_aeyGTrHdgMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/policy.vue?macro=true";
import { default as searchQsHDUWW6vuJWUHuKD_qZbd3yPG2fAKfGNDGVVAAlVYcMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/search.vue?macro=true";
import { default as reviewsvbLf5XIBewWIZYWgHxtmyzYT7TKIKUBQfhxDzCYVpL4Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/reviews.vue?macro=true";
import { default as contactsytG3OPU23yVnonB_PDDOaSTi4itmWhFaXvvr__4588I7YMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/contacts.vue?macro=true";
import { default as documentsAEG2x8DWBdrOdAYQOhfTZa0pCNk1Ry5y3eaSThZhwOkMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/documents.vue?macro=true";
import { default as teamPvlO0R6bFzu003dm1jL9zRzmTQZrwMgv3QGrqi9cnzoMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/team.vue?macro=true";
import { default as indexw6tqgNNZNCl_eG8NsqVjmxEYMJtUmzZdU30vZgTlQnEMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/cart/index.vue?macro=true";
import { default as indexIa7vxWTHmGdwUnKS1f53SRSKmOCg5UPC8dM3MMdqRagMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/news/index.vue?macro=true";
import { default as index2dR0dXfS5plgsuOxNqWrYmozx4ypMhD40HXgs9PAt8QMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/shop/index.vue?macro=true";
import { default as indexeVWZbBH5sQ2N_453X6jpDfKtqTUO9jFifFZOkGHnQnofoMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/index.vue?macro=true";
import { default as _91slug_93aZ55jBBob14_SlfNBYaSIJrev_VsnuEgcw1mahoeKIAMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/news/[slug].vue?macro=true";
import { default as _91slug_935kvFRApfGxV2N4NoG0q7kryKhfHtJwFSNyiyEapFTqsMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/shop/[slug].vue?macro=true";
import { default as failuregxTPxzEqrhr__454f1mG049dxImke8HNCEXxLKz3Aqi7wMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/cart/failure.vue?macro=true";
import { default as success8L4AYGbwQ4t6l8hyDb5hd4l7657goRc3iEJWgB8DfOAMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/cart/success.vue?macro=true";
import { default as certificatescTysaacDBuEccph3FyJt4YLwY1m4JvBmMQ_45G228h_45a8Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/certificates.vue?macro=true";
import { default as test50H500qaaX0VPerGmkJojyMyO_GQ3XOH9QrxIynnA9IMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/courses/test.vue?macro=true";
import { default as indexHjCaDrc_teiH5nr5IRhuiqpe9RMkHEw3Hu4CTi_45UdJIMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/courses/index.vue?macro=true";
import { default as index62nPPXQsW8qL_45c8_45PS_457fHZj3sLSMXYwoQcbT7YLI5AMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/index.vue?macro=true";
import { default as loginCLXM8VNgLpuqbsD7ow4PN4CP8H_NVCJltHlbC4Pc0poMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/login.vue?macro=true";
import { default as indexNUVxQwy4HSxWTdyWThB7lAnoDNMFV1FPEhJY2uemtqUMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/articles/index.vue?macro=true";
import { default as _91slug_93hG8l7EvJ7OIoQ75pQwGPYK4IjyCh7xl7pHG7ucdIVokMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/courses/[slug].vue?macro=true";
import { default as _91slug_93sFW03vlO8WlTv_FE4fwj0UWWNu0kte2MJ6A8fPsVFt4Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/articles/[slug].vue?macro=true";
import { default as registerWdZ6rX102V0pv79GNYfzD1H_tUDLJYe9006GbEY3H3UMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/register.vue?macro=true";
import { default as indexuleWeJM2aRVXUBGlsNz_yu2XHBiE8_vGkxjRxNgX9doMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/promotions/index.vue?macro=true";
import { default as _91slug_93wtpoHNnq4hJeBa9_xC45eoGcg3rxWMh9oiI0FNrN75QMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/promotions/[slug].vue?macro=true";
import { default as _91id_934EbsCcj3c_45KwNH2hs89nwWzBjKBZwMxo40DCc1_rQGoMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/careers/[id].vue?macro=true";
import { default as indexg5qjeNgdp0QOmjrwrCVt0srtCca7hvZEtApJdmQ0lPoMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/careers/index.vue?macro=true";
import { default as indexZYwtalFNQCR4ABU812njbNOD0y5WVdiGf01NL4vo03oMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/gallery/index.vue?macro=true";
import { default as _91slug_93gBAZqsbU2I0J2Be8xQFf4DWzNc0wxBewfVAOKzCtFBAMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/shop/product/[slug].vue?macro=true";
import { default as _91slug_934_45nMP2HSfrbd1D3dEFlzwLy0mZ8_lmsqYyMC44pdqSQMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/gallery/[slug].vue?macro=true";
import { default as forgot_45passwordhjb72_oMbZfai53kKaFVY9Lhdg1_45TmaU41GjInmo1sMMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/forgot-password.vue?macro=true";
import { default as _91hash_93LQ0T6gBQFkgtR_45QZKopLYF_45HWB8vWeP21U2gMdHXG4MMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/password-reset/[hash].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/index.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: policyptKIJZRyoyRnoieGEND1iAUWoSU0CJyjW_aeyGTrHdgMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/policy.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchQsHDUWW6vuJWUHuKD_qZbd3yPG2fAKfGNDGVVAAlVYcMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/search.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    meta: reviewsvbLf5XIBewWIZYWgHxtmyzYT7TKIKUBQfhxDzCYVpL4Meta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/reviews.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: contactsytG3OPU23yVnonB_PDDOaSTi4itmWhFaXvvr__4588I7YMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/contacts.vue")
  },
  {
    name: "documents",
    path: "/documents",
    meta: documentsAEG2x8DWBdrOdAYQOhfTZa0pCNk1Ry5y3eaSThZhwOkMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/documents.vue")
  },
  {
    name: "team",
    path: "/about/team",
    meta: teamPvlO0R6bFzu003dm1jL9zRzmTQZrwMgv3QGrqi9cnzoMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/team.vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexw6tqgNNZNCl_eG8NsqVjmxEYMJtUmzZdU30vZgTlQnEMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/cart/index.vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexIa7vxWTHmGdwUnKS1f53SRSKmOCg5UPC8dM3MMdqRagMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/news/index.vue")
  },
  {
    name: "shop",
    path: "/shop",
    meta: index2dR0dXfS5plgsuOxNqWrYmozx4ypMhD40HXgs9PAt8QMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/shop/index.vue")
  },
  {
    name: "about",
    path: "/about",
    meta: indexeVWZbBH5sQ2N_453X6jpDfKtqTUO9jFifFZOkGHnQnofoMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/index.vue")
  },
  {
    name: "news.view",
    path: "/news/:slug()",
    meta: _91slug_93aZ55jBBob14_SlfNBYaSIJrev_VsnuEgcw1mahoeKIAMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/news/[slug].vue")
  },
  {
    name: "shop.category",
    path: "/shop/:slug()",
    meta: _91slug_935kvFRApfGxV2N4NoG0q7kryKhfHtJwFSNyiyEapFTqsMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/shop/[slug].vue")
  },
  {
    name: "cart.failure",
    path: "/cart/failure",
    meta: failuregxTPxzEqrhr__454f1mG049dxImke8HNCEXxLKz3Aqi7wMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/cart/failure.vue")
  },
  {
    name: "cart.success",
    path: "/cart/success",
    meta: success8L4AYGbwQ4t6l8hyDb5hd4l7657goRc3iEJWgB8DfOAMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/cart/success.vue")
  },
  {
    name: "certificates",
    path: "/certificates",
    meta: certificatescTysaacDBuEccph3FyJt4YLwY1m4JvBmMQ_45G228h_45a8Meta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/certificates.vue")
  },
  {
    name: "courses.test",
    path: "/courses/test",
    meta: test50H500qaaX0VPerGmkJojyMyO_GQ3XOH9QrxIynnA9IMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/courses/test.vue")
  },
  {
    name: "courses",
    path: "/courses",
    meta: indexHjCaDrc_teiH5nr5IRhuiqpe9RMkHEw3Hu4CTi_45UdJIMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/courses/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: index62nPPXQsW8qL_45c8_45PS_457fHZj3sLSMXYwoQcbT7YLI5AMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/index.vue")
  },
  {
    name: "profile-login",
    path: "/profile/login",
    meta: loginCLXM8VNgLpuqbsD7ow4PN4CP8H_NVCJltHlbC4Pc0poMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/login.vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexNUVxQwy4HSxWTdyWThB7lAnoDNMFV1FPEhJY2uemtqUMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/articles/index.vue")
  },
  {
    name: "courses.view",
    path: "/courses/:slug()",
    meta: _91slug_93hG8l7EvJ7OIoQ75pQwGPYK4IjyCh7xl7pHG7ucdIVokMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/courses/[slug].vue")
  },
  {
    name: "articles.view",
    path: "/articles/:slug()",
    meta: _91slug_93sFW03vlO8WlTv_FE4fwj0UWWNu0kte2MJ6A8fPsVFt4Meta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/articles/[slug].vue")
  },
  {
    name: "profile-register",
    path: "/profile/register",
    meta: registerWdZ6rX102V0pv79GNYfzD1H_tUDLJYe9006GbEY3H3UMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/register.vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    meta: indexuleWeJM2aRVXUBGlsNz_yu2XHBiE8_vGkxjRxNgX9doMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/promotions/index.vue")
  },
  {
    name: "promotions.view",
    path: "/promotions/:slug()",
    meta: _91slug_93wtpoHNnq4hJeBa9_xC45eoGcg3rxWMh9oiI0FNrN75QMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/promotions/[slug].vue")
  },
  {
    name: "careers.view",
    path: "/about/careers/:id()",
    meta: _91id_934EbsCcj3c_45KwNH2hs89nwWzBjKBZwMxo40DCc1_rQGoMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/careers/[id].vue")
  },
  {
    name: "careers",
    path: "/about/careers",
    meta: indexg5qjeNgdp0QOmjrwrCVt0srtCca7hvZEtApJdmQ0lPoMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/careers/index.vue")
  },
  {
    name: "gallery",
    path: "/about/gallery",
    meta: indexZYwtalFNQCR4ABU812njbNOD0y5WVdiGf01NL4vo03oMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/gallery/index.vue")
  },
  {
    name: "shop.product",
    path: "/shop/product/:slug()",
    meta: _91slug_93gBAZqsbU2I0J2Be8xQFf4DWzNc0wxBewfVAOKzCtFBAMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/shop/product/[slug].vue")
  },
  {
    name: "gallery.album",
    path: "/about/gallery/:slug()",
    meta: _91slug_934_45nMP2HSfrbd1D3dEFlzwLy0mZ8_lmsqYyMC44pdqSQMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/about/gallery/[slug].vue")
  },
  {
    name: "profile-forgot-password",
    path: "/profile/forgot-password",
    meta: forgot_45passwordhjb72_oMbZfai53kKaFVY9Lhdg1_45TmaU41GjInmo1sMMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/forgot-password.vue")
  },
  {
    name: "profile-password-reset-hash",
    path: "/profile/password-reset/:hash()",
    meta: _91hash_93LQ0T6gBQFkgtR_45QZKopLYF_45HWB8vWeP21U2gMdHXG4MMeta || {},
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/app/pages/profile/password-reset/[hash].vue")
  }
]