import payload_plugin_jAbxMCOI5aEXgYbblVZeOBHN1Z3bwd8lA_zcSAvonEc from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_GytHVaCGGhcBTdLaPn0aw2YUv8_EDI6gF8kjuGYafhI from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uW_za_7DPStGE0urQNmniBZj__2ben2qrXiIggmgv8I from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NRFsL8wywgiasANYFVoMcOrDQ39Ia4PiRxZvktclf80 from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_aiMEshDaEJ6EP6W7u3CwUEvrcesBZraH0Po3SpxT7pA from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dRVmqFx19gDRgnaK1QixLpLeiK7SF7iouzxEsnznZ_0 from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_voat1vE1JQqjHhSHd7tCThl5XVRW3Lk66myNE7sfE4I from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_D_G8oy49DnyW2_c92HqhWcvpJQOszPlVTYO8ctEjXS0 from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_MribZ58p5tcAhXkVhfZeCCTUU1JLhAUjE6WlrqBXjzU from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_gAMDrfwK8p_DcJl0o5o8Cm_CouJC9TDdUAMJ_it5ycc from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_qmjjVJT2277qZTJjwAtJ4OvyRLQ_yB0N0zYDr8NLTlw from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.16.2_@parcel+watcher@2.5.1_@type_2f7b4426b49788f30964bb747d54d3f5/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4 from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_Bg_O8XGftv51FHgbwvFTBz9xZzxjSV_XIUtODZZ_OWI from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt-auth-sanctum@0.6.3/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import plugin_TK_5_xav4DmGuqtDUcrVuEcGVETFxbDlDOXPGoMAJUE from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_sass-embedde_d6f5392a852dc54e729d156a4375981c/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import maskito_eRCjuvC_F84zTUD1aCK3KdR1sSFq2cLPNmd_8bCXjOI from "/home/runner/work/flagman/flagman/apps/frontstore/layers/uikit/plugins/maskito.ts";
import social_share_F0EryXpLJtHMXd2FGZgmlFRtNP_BWW2AN5UCWQoJwiQ from "/home/runner/work/flagman/flagman/apps/frontstore/layers/uikit/plugins/social-share.ts";
import swiper_client__CmzSZvXqTMHC8UXm30Lr7VMS6cYiDtfwLkvWJK7QwI from "/home/runner/work/flagman/flagman/apps/frontstore/layers/uikit/plugins/swiper.client.ts";
import toast_client_Ous9IbfWfMeOqlisF_72fEIRXVqoS6sUg85OmxWKrSA from "/home/runner/work/flagman/flagman/apps/frontstore/layers/uikit/plugins/toast.client.ts";
import zod_i18n_5__riNcTHqvDpnwMljcgbKWNNrJ20jdN4sgl_nWhUQg from "/home/runner/work/flagman/flagman/apps/frontstore/layers/uikit/plugins/zod-i18n.ts";
import api_ZuiyydjgBjRnWK94z3TrVAxnNLlbAVJmpvTPfK9upW4 from "/home/runner/work/flagman/flagman/apps/frontstore/layers/api/plugins/api.ts";
import luxon_HW0GziPqDM5HXroNs9giSB_XB2KcSZThVQGQzqfVvMg from "/home/runner/work/flagman/flagman/apps/frontstore/app/plugins/luxon.ts";
import text_highlight_yoQHkuzakZmOag_rtaFk29K8BCqPU9Yle9sJ4RV70Ag from "/home/runner/work/flagman/flagman/apps/frontstore/app/plugins/text-highlight.ts";
export default [
  payload_plugin_jAbxMCOI5aEXgYbblVZeOBHN1Z3bwd8lA_zcSAvonEc,
  revive_payload_client_GytHVaCGGhcBTdLaPn0aw2YUv8_EDI6gF8kjuGYafhI,
  unhead_uW_za_7DPStGE0urQNmniBZj__2ben2qrXiIggmgv8I,
  router_NRFsL8wywgiasANYFVoMcOrDQ39Ia4PiRxZvktclf80,
  _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM,
  payload_client_aiMEshDaEJ6EP6W7u3CwUEvrcesBZraH0Po3SpxT7pA,
  navigation_repaint_client_dRVmqFx19gDRgnaK1QixLpLeiK7SF7iouzxEsnznZ_0,
  check_outdated_build_client_voat1vE1JQqjHhSHd7tCThl5XVRW3Lk66myNE7sfE4I,
  chunk_reload_client_D_G8oy49DnyW2_c92HqhWcvpJQOszPlVTYO8ctEjXS0,
  plugin_vue3_MribZ58p5tcAhXkVhfZeCCTUU1JLhAUjE6WlrqBXjzU,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_gAMDrfwK8p_DcJl0o5o8Cm_CouJC9TDdUAMJ_it5ycc,
  plugin_qmjjVJT2277qZTJjwAtJ4OvyRLQ_yB0N0zYDr8NLTlw,
  plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4,
  plugin_Bg_O8XGftv51FHgbwvFTBz9xZzxjSV_XIUtODZZ_OWI,
  plugin_TK_5_xav4DmGuqtDUcrVuEcGVETFxbDlDOXPGoMAJUE,
  maskito_eRCjuvC_F84zTUD1aCK3KdR1sSFq2cLPNmd_8bCXjOI,
  social_share_F0EryXpLJtHMXd2FGZgmlFRtNP_BWW2AN5UCWQoJwiQ,
  swiper_client__CmzSZvXqTMHC8UXm30Lr7VMS6cYiDtfwLkvWJK7QwI,
  toast_client_Ous9IbfWfMeOqlisF_72fEIRXVqoS6sUg85OmxWKrSA,
  zod_i18n_5__riNcTHqvDpnwMljcgbKWNNrJ20jdN4sgl_nWhUQg,
  api_ZuiyydjgBjRnWK94z3TrVAxnNLlbAVJmpvTPfK9upW4,
  luxon_HW0GziPqDM5HXroNs9giSB_XB2KcSZThVQGQzqfVvMg,
  text_highlight_yoQHkuzakZmOag_rtaFk29K8BCqPU9Yle9sJ4RV70Ag
]